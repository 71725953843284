


















import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import { SfImage } from "@storefront-ui/vue";
import { useContent } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import ContentBlock from "./ContentBlock.vue";
import { useConfig } from "~/composables";

export default defineComponent({
  name: "CategoryList",
  components: {
    ContentBlock,
    SfImage,
  },
  setup() {
    const { loadBlocks } = useContent();
    const blocks = ref<CmsBlock[]>([]);
    const blockLiContent = ref([]);
    const { config } = useConfig();
    const isLoaded = ref(false);
    const baseUrl = config.value.secure_base_url;

    const { fetch, fetchState } = useFetch(async () => {
      blocks.value = await loadBlocks({ identifiers: ["category-list"] });
      const re = /<li>(.*?)<\/li>/gs;
      const str = blocks?.value?.[0]?.content;
      let match;
      let i = 0;

      while ((match = re.exec(str)) !== null) {
        let a_herf = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/.exec(match?.[0]);
        let img_src = /img\s+.*?src="([^"]*)"/.exec(match?.[0]);
        let title = match?.[0].replace(/(<([^>]+)>)/gi, "");
        blockLiContent.value[i] = {
          link: a_herf?.[1].replace(baseUrl, "") ?? null,
          img: img_src[1] ?? null,
          title: title ?? null,
        };
        if (i == (match.length - 1)) {
          isLoaded.value = true;
        }
        i++;
      }
    });
    fetch();

    return {
      blocks,
      blockLiContent,
      isLoaded
    };
  },
});
